import React from "react";
import { Provider } from "react-redux";
import ReduxToastr from "react-redux-toastr";
import logo from './logo.svg';
import './App.css';
import createPersistor from "./redux/store/index";
import Routes from "./routes/Routes";
import { PersistGate } from "redux-persist/integration/react";
import { createBrowserHistory } from "history";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import GoogleTagManager from "./MetaComponents/GoogleTagManager";
import AndroidFacebookAlert from './components/AndroidFacebookAlert';

const { persistor, store } = createPersistor();

const App = () => (

  <Provider store={store}>
     <PersistGate persistor={persistor}> 
     <GoogleTagManager />
      {/* <ConnectedRouter history={history}> */}
      <ToastContainer limit={1} />
      <AndroidFacebookAlert />
        {/* <ReduxToastr
          timeOut={3000}
          newestOnTop={true}
          position="top-right"
          transitionIn="fadeIn"
          transitionOut="fadeOut"
          progressBar
          closeOnToastrClick
        /> */}
        <Routes {...store} />
      {/* </ConnectedRouter> */}
     </PersistGate> 
  </Provider>
);

// QuUkyefzL4Chxhj5L6Yw
export default App;
