import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';

const AndroidFacebookAlert = () => {
  // State to control the modal visibility.
  const [showModal, setShowModal] = useState(false);

  // Function to detect if the user agent is Android.
  const isAndroid = () => {
    return /Android/i.test(navigator.userAgent);
  };

  // Function to detect if the browser is Facebook In-App Browser.
  const isFacebookInAppBrowser = () => {
    const ua = navigator.userAgent || navigator.vendor || window.opera;
    // Optionally: you can log or display the user agent somewhere if needed.
    // For example: console.log("User Agent:", ua);
    return ua.includes("AI Build") ||
      ua.includes("wv") ||
      ua.includes("[FB_IAB") ||
      ua.includes("FBAV");
  };

  // Check on component mount whether to show the modal.
  useEffect(() => {

    if (isFacebookInAppBrowser()) {
      setShowModal(true);
    }
  }, []);

  // Handler to close the modal.
  const handleClose = () => setShowModal(false);

  return (
    <div>
      {/* Optional: Display the user agent (hidden by default) */}
      <div id="user-agent" style={{ display: 'none' }}>
        {navigator.userAgent}
      </div>

      {/* Modal that shows when conditions are met */}
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header style={{ position: 'relative' }}>
          <Modal.Title>Open in External Browser</Modal.Title>
          <button
            type="button"
            className="close"
            onClick={handleClose}
            style={{
              position: 'absolute',
              top: '15px',
              right: '15px',
              background: 'transparent',
              border: 'none',
              fontSize: '1.5rem',
              lineHeight: '1',
            }}
          >
            <span>&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body>
        For the best experience, tap the menu icon (three dots) in the top right corner of the screen and select 'Open in external browser'
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AndroidFacebookAlert;
